body {
  background: #f3f3f3 !important;
}
.card {
  background: #fbfbfb !important;
}
.clickable:hover {
  cursor: pointer;
  background: #dbe9ec;
  color: #606060 !important;
}
.clickable:hover .text-muted {
  color: #727272 !important;
}

.activityBar {
  height: 100vh;
  background: rgb(255, 253, 253);
  right: 0px;
  top: 0px;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow: auto;
}
.content {
  transition: margin 1s;
  z-index: 99;
}
.navbar {
  position: sticky;
  top: 0px;
  z-index: 100;
  background: #f3f3f3;
  border-bottom: solid 1px #eeeeee;
}
.mainNavbar {
  min-height: 95vh;
  overflow-y: auto;
}

.sidebar {
  z-index: 99;
}

.button-toggle {
  position: absolute;
  top: 1.5em;
  z-index: 100;
  color: #fff;
  transition: border-color 1s, background-color 0.7s, margin-left 1s,
    transform 0.5s;
}

.button-toggle:hover {
  transform: rotate(90deg);
  background-color: rgba(255, 253, 255, 0.1);
}

.rotate {
  transform: rotate(90deg);
}

.rotate:hover {
  transform: rotate(0deg);
}

@media (min-width: 768px) {
  .content {
    margin-left: 260px;
    /*margin-right: 260px;*/
  }
  .button-toggle {
    margin-left: 190px;
  }
}

@media (max-width: 767px) {
  .button-toggle {
    display: none;
  }
}

/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/

/* Timeline holder */

ul.timeline {
  list-style-type: none;
  position: relative;
  padding-left: 1.5rem;
}

/* Timeline vertical line */

ul.timeline:before {
  content: " ";
  background: #fff;
  display: inline-block;
  position: absolute;
  left: 16px;
  width: 4px;
  height: 100%;
  z-index: 400;
  border-radius: 1rem;
}

li.timeline-item {
  margin: 20px 30px;
}

/* Timeline item arrow */

.timeline-arrow {
  border-top: 0.5rem solid transparent;
  border-right: 0.5rem solid #fff;
  border-bottom: 0.5rem solid transparent;
  display: block;
  position: absolute;
  left: 2rem;
}

/* Timeline item circle marker */

li.timeline-item::before {
  content: " ";
  background: #ddd;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #fff;
  left: 11px;
  width: 14px;
  height: 14px;
  z-index: 400;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.invalid {
  color: red;
}

/*
 *  CUSTOM SCROLL BAR 
 */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
}
.navbar {
  z-index: 1;
}
.primaryNavBarWithLogo {
  z-index: 2;
}
.card-title {
  margin-bottom: 0px;
}

.modalRight .modal-header {
  height: 5vh;
}
.modalRight .modal-footer {
  height: 10vh;
}
.modalRight .modal-body {
  height: 85vh;
  overflow: auto;
}
.modalRight .modal-content {
  height: 100vh;
  border-radius: 0px;
}

.modalLeft .modal-dialog {
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.modalLeft .modal-content {
  height: 100vh;
  border-radius: 0px;
}

.omniBar {
  height: 100vh;
  width: 60px;
  background-color: #1d202c;
  position: fixed;
  text-align: center;

  padding-top: 80px;
}

label {
  font-size: 14px;
}

.omniBar .active {
  background-color: #262b40;
  border-bottom: #343b53 1px solid;
  border-radius: 0px;
}
.modalRight .header {
  border-bottom: 0.0625rem solid #eaedf2;
  display: block;
}

/* CHAT */
.onlineUsers {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.onlineUsers li {
  float: left;
  padding: 0;
  margin: 0;
  width: 32px;
}

.chatSidebar {
  position: fixed;
  right: 0px;
  bottom: 0px;
  height: 100vh;
  z-index: 3;
  background: white;
}
.chatSidebar.conversation {
  max-height: 50% !important;
  bottom: 0px;
}

.chatSidebar.conversation .messages {
  height: 70%;
  overflow: hidden;
  overflow-y: auto;
}
.chatSidebar.conversation .messageContainer {
  height: 30%;
  padding-bottom: 5%;
  padding-top: 1%;
}
.chatSidebar .conversationContainer {
  position: relative;
  height: 100%;
}
nav .btn .icon-badge {
  top: 3px !important;
}

.ui.search .prompt {
  border-radius: 0px !important;
  width: 100% !important;
  border-radius: 0.5rem !important;
  border: 0.0625rem solid #d1d7e0 !important;
}
.ui.search .ui.input {
  width: 100% !important;
}

.message .form-control {
  border: 0px !important;
}
.withUnderline {
  border-bottom: 1px solid #e2e2e2 !important;
}

.modalRight.message {
  height: calc(100% - 20vh) !important;
  bottom: 0px;
  position: absolute;
  top: auto;
}
.ql-editor {
  height: 100% !important;
}
.modalRight.message .modal-body {
  height: auto;
}
.modalRight.message .modal-content {
  height: auto;
}

#ModalIframe {
  width: 100%;
  height: 100%;
  border: 0px;
}
.full .modal-content {
  height: 90vh !important;
}
.react-confirm-alert-overlay {
  z-index: 9999 !important;
}

.pendingPayment {
  box-shadow: -5px 0px 0px 0px orangered;
}
.partialPayment {
  box-shadow: -5px 0px 0px 0px orange;
}
.completePayment {
  box-shadow: -5px 0px 0px 0px greenyellow;
}

.sidebar .nav-item {
  --bs-accordion-bg: transparent !important;
  border: 0px;
}
.mailContainer .modal-dialog {
  bottom: 0px;
  right: 0px;
  position: absolute;
  max-width: 40%;
  padding: 0px;
  margin: 0px;
  height: 50%;
}

.mailContainer .content {
  max-height: 400px;
  height: 400px;
}
.sidebar .accordion {
  --bs-accordion-border-color: transparent !important;
}

/* Random calendar*/
.fc-theme-standard td,
.fc-theme-standard th {
  border: 0px !important;
  border-bottom: 1px solid #ddd !important;
  border-top: 1px solid #ddd !important;
}
/*Position the modals on the screen*/
.modalRight .modal-dialog {
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.calendarStyle .fc-daygrid-day-frame {
  max-height: 35px;
}

.fc-daygrid-day {
  z-index: 1;
}
.fc-col-header-cell {
  background-color: #3c3c3c;
  border: solid 1px #3c3c3c !important;
}
.fc-col-header-cell a {
  color: #fff;
}

.vertical-timeline-element-date {
  color: rgba(0, 0, 0, 0.9) !important;
}
.vertical-timeline-element-icon {
  text-align: center;
  vertical-align: middle;
}

/*Customer buttons*/

.hidde-buttons {
  transition: opacity 1s;
}
