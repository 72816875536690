.container {
	display: flex;
	width: 100%;
	padding: 0;
	
}

.choice {
	/* height: 180px; */
	height:80vh;
	box-sizing: border-box;
	padding: 0;
	overflow: hidden;
	float: left;
	align-items: center;
	transition: width 0.6s;
	border-radius: 3px;
}

.expand {
	width: 65%;
}

.unset {
	width: 16%;
	color: black !important;
	background-color: #ddd !important;
}

.small {
	width: 5%;
	background-color: #ddd !important;
}

.small>div {
	opacity: 0;
}

.unset>div>p {
	opacity: 0;
}

.expand>div {
	transition-delay: 300ms;
	opacity: 1;
}
/* CUSTOMS */
.ochoa.small {
	background: #dd1e36 !important;
	color: #fff !important;
}

.ochoa.small .card-title {
	color: #fff !important;
}

.bluesteel.small{
	background: #18a1fd !important;
	color: #fff !important;
}
.bluesteel .card-title {
	color: #fff !important;
}

.codevi.small {
	background: #156e90 !important;
	color: #fff !important;
}